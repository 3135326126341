import { DfaStatusEnum } from '@dltru/dfa-models'
import { Box, Button, MiniModal, QuestionCircle } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { revokeEmitment } from '@store/dfa/details'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'

export interface ICancelModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
}

export const CancelIssueModal: FC<ICancelModal> = ({ isModalVisible, setIsModalVisible }) => {
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const reduxDispatch = useDispatch()

    const handleOk = () => {
        if (dfaDetails?.id) {
            reduxDispatch(revokeEmitment(dfaDetails.id))
            setIsModalVisible(false)
        }
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const isIssuerConfirmationStatus = dfaDetails?.status === DfaStatusEnum.issuer_confirmation

    return (
        <MiniModal visible={isModalVisible} onCancel={handleCancel}>
            <div>
                <Box direction="row" align="center">
                    <Box className="modal-content-shift">
                        <QuestionCircle className="modal-icon modal-question-icon" />
                    </Box>
                    <div className="ant-modal-title">
                        {isIssuerConfirmationStatus
                            ? 'Отменить выпуск'
                            : 'Заявление об аннулировании выпуска'}
                    </div>
                </Box>
                <Box direction="row" align="center" justify="right">
                    <div className="modal-content-shift">&nbsp;</div>
                    <p className="modal-content-text">
                        Вы действительно хотите
                        {isIssuerConfirmationStatus
                            ? ' отменить выпуск '
                            : ' подать заявление об аннулировании выпуска '}
                        ЦФА?
                    </p>
                </Box>
                <Box
                    className="ant-modal-footer ant-modal-footer-no-border"
                    padding={[0, 8, 8, 8]}
                    direction="row"
                    justify="right"
                >
                    <Button borderRadius={12} onClick={handleCancel}>
                        Отмена
                    </Button>
                    <Button borderRadius={12} type="primary" onClick={handleOk}>
                        Да,{isIssuerConfirmationStatus ? ' отменить выпуск' : ' подать заявление'}
                    </Button>
                </Box>
            </div>
        </MiniModal>
    )
}
