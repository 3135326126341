import { Alert, Box, VIEW_DATE_FORMAT } from '@dltru/dfa-ui'
import dayjs from 'dayjs'
import { FC } from 'react'

interface IComponentProps {
    decliningReason?: string
    decliningDate?: number
}
export const IssueDeclinedAlertBlock: FC<IComponentProps> = ({
    decliningReason,
    decliningDate,
}) => {
    const message = 'Выпуск признан несостоявшимся'
    const description = (
        <Box padding={[0, 48, 0, 0]}>
            {decliningDate && (
                <>
                    <span>{dayjs.unix(decliningDate).format(VIEW_DATE_FORMAT)}</span>
                    <br />
                </>
            )}
            {Boolean(decliningReason) && <span>{decliningReason}</span>}
            {/* TODO выводятся файлы, которые мы берем неизвестно откуда */}
            {/* <UneditableUploadedFiles files={[{ uid: 'file' }]} /> */}
        </Box>
    )
    return <Alert message={message} description={description} type="error" showIcon />
}
