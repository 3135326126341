import { DfaStatusEnum, IApprovalHistoryDfa } from '@dltru/dfa-models'
import { Alert, Box, VIEW_DATE_FORMAT } from '@dltru/dfa-ui'
import dayjs from 'dayjs'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { authSelector } from '@store/auth/selectors'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import api from '@services/api'

import './style.less'

export const AlertApproval: FC = () => {
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const uuid = useSelector(authSelector.selectUserUid)
    const [approvalHistory, setApprovalHistory] = useState([] as IApprovalHistoryDfa[])
    // TODO Переделать на стор
    const getApprovalHistory = async () => {
        const { data } = await api.lib.getApprovalHistoryDfa(dfaDetails.id)
        setApprovalHistory(data?.items ?? [])
    }
    const showButton =
        (dfaDetails.status === DfaStatusEnum.rejected ||
            dfaDetails.status === DfaStatusEnum.modification) &&
        dfaDetails.emitter_id === uuid
    useEffect(() => {
        if (showButton) {
            getApprovalHistory()
        }
    }, [showButton])
    const lastItem = approvalHistory.slice(-1).pop()
    if (!showButton) {
        return null
    }

    return (
        <Box margin={[0, 0, 8, 0]}>
            <Alert
                showIcon
                type={dfaDetails.status === DfaStatusEnum.rejected ? 'error' : 'warning'}
                message={
                    dfaDetails.status === DfaStatusEnum.rejected
                        ? 'В выпуске ЦФА отказано'
                        : 'Заявка направлена на доработку'
                }
                description={
                    <div>
                        <span className="gray-text">
                            {lastItem?.set_status_date
                                ? dayjs(lastItem.set_status_date).format(VIEW_DATE_FORMAT)
                                : undefined}
                        </span>
                        <div>{lastItem?.comment}</div>
                    </div>
                }
            />
        </Box>
    )
}
