import { ArrowLeft, Box, Button, CommonInfoRefill } from '@dltru/dfa-ui'
import { FC, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { moneySelector } from '@store/money/selectors'

import { EActiveBodyType } from '../types'

interface IComponentProps {
    onClose: (value: EActiveBodyType) => void
}

export const AccountRefillInfo: FC<IComponentProps> = ({ onClose }) => {
    const account = useSelector(moneySelector.selectAccount)

    const onClickHandler = useCallback(() => {
        onClose(EActiveBodyType.Payment)
    }, [])

    return (
        <div>
            <Box padding={[0, 0, 16, 0]}>
                <Button
                    className="trade-additional-modal__text-button"
                    type="text"
                    icon={<ArrowLeft />}
                    onClick={onClickHandler}
                    width="214px"
                >
                    Как пополнить счёт
                </Button>
            </Box>

            <Box padding={[0, 0, 24, 0]}>
                Пополните счёт банковским переводом по указанным реквизитам
            </Box>
            {account && <CommonInfoRefill account={account} />}
        </div>
    )
}
