import { Alert, Button } from "@dltru/dfa-ui"
import { FC } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { TabName } from "../../EmissionCardPage"
import './style.less'

type ComponentProps = {
  date: string
}

export const CouponPaymentAlert: FC<ComponentProps> = ({ date }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const onLinkClick = () => {
    navigate(`${location.pathname}#${TabName.coupon_payment}`)
  }

  return <Alert
    showIcon
    type="warning"
    description={
      <>
        <div className="coupon-payment-alert">{date} вам необходимо
          <Button
            type="link"
            onClick={onLinkClick}
            size="small"
          >
            выплатить купонный доход
          </Button>
          владельцам ЦФА.
        </div>
        <div>Убедитесь, что на вашем лицевом счете достаточно денежных средств, после чего заполните соответствующую форму.</div>
      </>
    }
  />
}