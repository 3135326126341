import { RedemptionTypeDfa } from '@dltru/dfa-models'
import { Alert, Button, EMPTY_FIELD_VALUE, RefillModal } from '@dltru/dfa-ui'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { moneySelector } from '@store/money/selectors'

import './style.less'

export const RedeemAlert: FC = () => {
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const account = useSelector(moneySelector.selectAccount)
    const [isModal, setIsModal] = useState(false)
    const date = dfaDetails.redeem_date
        ? dayjs(dfaDetails.redeem_date).locale('ru').format('DD MMMM YYYY')
        : EMPTY_FIELD_VALUE
    const sum =
        dfaDetails.redeem_price_per_dfa && dfaDetails.original_amount
            ? (dfaDetails.redeem_price_per_dfa * dfaDetails.original_amount).toLocaleString()
            : EMPTY_FIELD_VALUE

    const description = (
        <>
            {date} вам необходимо провести погашение данного выпуска ЦФА. <br />
            {dfaDetails.redemption_type === RedemptionTypeDfa.fixed
                ? `До наступления даты погашения для выполнения обязательств по погашению ЦФА внесите на лицевой счет сумму ${sum} руб.`
                : 'Для этого вам необходимо до наступления даты погашения обеспечить наличие достаточной суммы денежных средств на вашем лицевом счете. Сумма погашения должна быть рассчитана вами в дату погашения в соответствии с порядком определения цены погашения, указанным в Решении о выпуске. Комиссия за погашение определяется действующими тарифами Оператора ИС'}
            <Button
                type="link"
                size="small"
                onClick={() => setIsModal(true)}
                className="warning-link-btn"
            >
                Пополнить счет
            </Button>
        </>
    )
    return (
        <>
            <Alert
                showIcon
                type="warning"
                message="Подготовьтесь к погашению ЦФА"
                description={description}
            />
            <RefillModal isModal={isModal} setIsModal={setIsModal} account={account} />
        </>
    )
}
